import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';

// Firebase
import { AngularFireStorage } from '@angular/fire/storage';
import { v1 } from 'uuid';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() folder: string;
  @Input() file: string;
  @Output() resourceUploaded = new EventEmitter<object>();

  // Extensions
  extensionLists = {
    video: ['m4v', 'avi','mpg','mp4', 'webm'],
    image: ['jpg', 'jpeg', 'gif', 'bmp', 'png']
  };
  currentFileExt: string = '';

  // Firebase
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  imgURL: string;

  progressStyle = 'progress d-none';

  constructor(
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.getImage(this.file);
  }

  getImage(image: string): void {
    if (image) {
      this.imgURL = image;
    }
  }

  activeInputFile(inputFile): void {
    inputFile.click();
  }

  upload(event): void {
    const file = event.target.files[0];
    const newName = v1();

    if (file) {
      this.currentFileExt = file.name.split('.').pop();
      const filePath = `${environment.env}/${this.folder}/${newName}.${this.currentFileExt}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      // observe percentage changes
      this.uploadProgress = task.percentageChanges();
      this.uploadProgress.subscribe(data => {
        this.progressStyle = 'progress';
        if (data==100) {
          this.progressStyle = 'progress d-none';
        }
      });
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(data => {
              this.imgURL = data;
              const split = data.split('token=');
              const resourceToken = split[1];
              this.onSubmitresourceUploaded(data, `${newName}.${this.currentFileExt}`, this.currentFileExt, resourceToken);
            });
          })
      )
      .subscribe()
    }
  }

  validFileExtension(ext: string): boolean {
    // True = video
    if (this.extensionLists.video.indexOf(ext)>-1) {
      return true;
    }
    return false;
  }

  onSubmitresourceUploaded(url: string, resourceName: string, resourceType: string, resourceToken: string): void {
    this.resourceUploaded.emit({'resourceUrl': url, 'resourceName': resourceName, 'resourceType': resourceType, 'resourceToken': resourceToken});
  }

}
