export const environment = {
  env: 'dev',
  storageRootFolder: 'dev',
  firebasestorageUrl: 'https://firebasestorage.googleapis.com/v0/b/theo-a2ef9.appspot.com/o/',
  firebase: {
    firabaseRootFolder: '/env',
    apiKey: 'AIzaSyC0J8HurSQvchV7mXnwZfj_xh8UT02g4bM',
    authDomain: 'theo-a2ef9.firebaseapp.com',
    databaseURL: 'theo-a2ef9.firebaseio.com',
    projectId: 'theo-a2ef9',
    storageBucket: 'theo-a2ef9.appspot.com',
    imgUrlBase: `https://firebasestorage.googleapis.com/v0/b/theo-a2ef9.appspot.com/o/dev%2F`,
  },
  serviceUrl : 'https://theoapp.sudtechnologies.com/',
  notificationEndPoint: 'https://theoapp.sudtechnologies.com/',
  tokenAuthString: 'Basic YW5kcm9pZF9jbGllbnQ6MTIzNDU2',
  production: false,
};

