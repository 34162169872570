import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '@nebular/auth';
import { ValidationInterface } from './inteface/validation-interface';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  redirectDelay: number = 0;

  errors: string[] = [];
  messages: string[] = [];
  user:any = { rememberMe: false }
  

  showMessages: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];

  validation:ValidationInterface;

  constructor(protected auth: AuthService, @Inject(NB_AUTH_OPTIONS) protected config = {}, protected router: Router) {
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    //console.log(this.getConfigValue('forms.validation'));
    this.validation = this.getConfigValue('forms.validation');
  }

  ngOnInit() {
  }

  loginEmail() {
    this.errors = this.messages = [];
    this.submitted = true;

    this.auth.signInWithEmail(this.user.email, this.user.password)
      .then((res: any) => {
        //console.log(res);
        this.submitted = false;
        // this.messages = [res];

        this.redirectToDashboard()
      })
      .catch((err) => {
        this.submitted = false;
        this.errors = [err];
      });
  }

  redirectToDashboard(){
    setTimeout(() => {
      this.router.navigate(['/pages']);
    }, this.redirectDelay);
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

}
